(function($) {

    $(document).on('ready', function() {
        if($('#wpadminbar').length > 0) {
            $('.main').css('margin-top', (parseInt($('.main').css('margin-top')) - $('#wpadminbar').outerHeight() - 2));
        }

        var months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
        var selectedMonthName = months[new Date().getMonth()];
        $('span.dynamic-month').text(selectedMonthName);

        var scroll = new SmoothScroll('a[href*="#"]', {
            speed: 500,
            speedAsDuration: true,
            offset: parseInt($('header').outerHeight())
        });
    });

    $('.image-slider .slider').slick({
        slidesToScroll: 1,
        slidesToShow: 3,
        arrows: false,
        dots: true,
        infinite: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    $('.faq-section .card .card-header').click(function(){

        var questionID = $(this).attr('id');

        if($('.card-header#' + questionID + ' .question').hasClass('collapsed')) {
            $('.card-header#' + questionID + ' .question').removeClass('collapsed');
            $('.card #' + questionID + '.collapse').removeClass('collapse').addClass('visible');
        } else {
            $('.card-header#' + questionID + ' .question').addClass('collapsed');
            $('.card #' + questionID + '.visible').removeClass('visible').addClass('collapse');
        }

    });

    $(document).on('click', '.inline-video-trigger', function(){
        if($(this).data('video-id')) {
            var iframeHTML = '<iframe src="https://www.youtube.com/embed/' + $(this).attr('data-video-id') + '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

            $(this).parent('.video-preview-container').find('iframe').remove();
            $(this).parent('.video-preview-container').append(iframeHTML);
        } else {
            console.error('no video ID provided.');
        }
    });

    $('.single-product .entry-summary .btn-primary').click(function(){
        $('.popup-form-container').addClass('active');

        if($('form input[name="field[6]"]').length > 0) {
            $('form input[name="field[6]"]').val(window.location.href);
        }
    });

    $('.popup-form-container .background').click(function(){
        if($('.popup-form-container').hasClass('active')) {
            $('.popup-form-container').removeClass('active');
        }
    });

})(jQuery);
